
  import { Component, Prop } from 'vue-property-decorator'

  import Row from './row.vue'
  import { ChipProps } from './interfaces'

@Component({
  components: {
    Row,
  },
})
  export default class Chip extends Row implements ChipProps {
  @Prop({ type: Boolean, default: true }) dark!: boolean;
  @Prop({ type: Boolean }) small!: boolean;

  get properties () {
    const { color, dark, small, outlined } = this
    return { color, dark, small, outlined }
  }
  }
